var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "game-type7 my-2", attrs: { id: "game-" + _vm.data.block } },
    [
      _c(
        "div",
        {
          staticClass: "swiperBox d-flex",
          style: { height: _vm.swiperHeight + "px" }
        },
        [
          _c(
            "swiper",
            {
              ref: "gameItem",
              staticClass: "myTab py-0",
              attrs: { options: _vm.tabOption }
            },
            _vm._l(_vm.data.data.navbar, function(item, index) {
              return _c(
                "swiper-slide",
                { key: item._id, staticStyle: { width: "auto" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "game__item--card cursor-pointer",
                      on: {
                        click: function($event) {
                          return _vm.getGameType("tab", index)
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "game-card d-flex justify-center flex-column align-center pt-1",
                          class: {
                            "swiper-slide-active": _vm.tabIndex === index
                          }
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "imgBox",
                              class: [_vm.tabIndex === index ? "primary" : ""]
                            },
                            [
                              _vm.navIcon(item)
                                ? _c("img", {
                                    attrs: {
                                      src: _vm.baseImgUrl + _vm.navIcon(item),
                                      height: "25"
                                    }
                                  })
                                : _c("Icon", {
                                    staticClass: "bg--text",
                                    attrs: {
                                      data: _vm.icons[item.class],
                                      width: "20",
                                      height: "26"
                                    }
                                  })
                            ],
                            1
                          ),
                          _c(
                            "strong",
                            {
                              class: [
                                _vm.tabIndex === index
                                  ? "primary--text"
                                  : "subtitle--text"
                              ]
                            },
                            [_vm._v(" " + _vm._s(item.game_type))]
                          )
                        ]
                      )
                    ]
                  )
                ]
              )
            }),
            1
          ),
          _c(
            "swiper",
            {
              ref: "gameItem",
              staticClass: "mySwiper pl-1",
              attrs: { options: _vm.swiperOption }
            },
            [
              _c(
                "swiper-slide",
                { staticStyle: { width: "auto" } },
                [
                  _vm.activeNavbarType
                    ? _c(
                        "v-tabs",
                        {
                          staticClass: "game__tab",
                          attrs: {
                            height: "38",
                            "background-color": "bg",
                            color: "primary"
                          },
                          model: {
                            value: _vm.subTabIndex,
                            callback: function($$v) {
                              _vm.subTabIndex = $$v
                            },
                            expression: "subTabIndex"
                          }
                        },
                        _vm._l(_vm.activeNavbarContent, function(game, index) {
                          return _c(
                            "v-tab",
                            {
                              key: game._id,
                              staticClass: "px-0",
                              on: {
                                click: function($event) {
                                  return _vm.getGameType("subTab", index)
                                }
                              }
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "caption",
                                  class: [
                                    _vm.subTabIndex === index
                                      ? "primary--text"
                                      : "subtitle--text"
                                  ]
                                },
                                [_vm._v(_vm._s(game.title))]
                              )
                            ]
                          )
                        }),
                        1
                      )
                    : _vm._e(),
                  _vm.activeNavbarType
                    ? _c("SlotTemplate", {
                        staticClass: "Items3",
                        style: "height: " + (_vm.swiperHeight - 40) + "px",
                        attrs: {
                          "active-sub-tab-type": _vm.activeSubTabType,
                          "active-page": _vm.activeNavbarType
                        }
                      })
                    : _c(
                        "v-row",
                        {
                          staticClass: "px-4 pt-2",
                          staticStyle: { overflow: "auto" }
                        },
                        _vm._l(_vm.activeNavbarContent, function(item, index) {
                          return _c(
                            "v-col",
                            {
                              key: item._id,
                              staticClass:
                                "game__item--card cursor-pointer d-flex justify-center flex-column align-center",
                              class: _vm.activeNavbarType ? "cols4" : "cols6",
                              style: _vm.activeNavbarType
                                ? { height: "30vw" }
                                : { height: "37vw" },
                              attrs: { cols: _vm.activeNavbarType ? 4 : 6 }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "itemContent d-flex flex-column justify-center align-center",
                                  class:
                                    item.status === "0" ? "maintenanceBox" : "",
                                  style: _vm.gradientBg
                                },
                                [
                                  item.status == "0"
                                    ? _c("maintenance")
                                    : _vm._e(),
                                  !_vm.activeNavbarType
                                    ? _c(
                                        "strong",
                                        { staticClass: "white--text" },
                                        [_vm._v(_vm._s(item.title))]
                                      )
                                    : _vm._e(),
                                  !_vm.activeNavbarType
                                    ? _c("img", {
                                        attrs: {
                                          src: _vm.baseImgUrl + item.image_h5,
                                          width: "100%"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.lauchGame(
                                              item.game_code,
                                              item.hyperlink,
                                              item.class
                                            )
                                          }
                                        }
                                      })
                                    : _vm._e(),
                                  _vm.activeNavbarType
                                    ? _c("img", {
                                        attrs: {
                                          src: _vm.baseImgUrl + item.game.image,
                                          width: "100%"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.lauchGame(
                                              item.game_code,
                                              item.hyperlink,
                                              item.class
                                            )
                                          }
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              ),
                              _vm.activeNavbarType
                                ? _c(
                                    "strong",
                                    {
                                      class: [
                                        _vm.subTabIndex === index
                                          ? "primary--text"
                                          : "subtitle--text"
                                      ]
                                    },
                                    [_vm._v(_vm._s(item.localized_name))]
                                  )
                                : _vm._e()
                            ]
                          )
                        }),
                        1
                      )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }